import TicketShop from "components/TicketShop";
import React from "react";
import Helmet from "react-helmet";

export default function EventsWarehouseProjectPage(p) {
  const title = "The Warehouse Project | Manchester Students Group";
  const description = "Exclusive prices for the biggest night in the UK!";

  return (
    <main className="event-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <h1>The Warehouse Project</h1>
      <h3>Exclusive prices for the biggest night in the UK!</h3>
      <div>
        <TicketShop
          scriptString={`
            <script
              src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js"
              data-fixr-shop-id="4045835d-70f7-4643-b843-c30108db507a"
              data-fixr-theme="dark"
              data-name-filter="warehouse"
            ></script>
          `}
        />
      </div>
    </main>
  );
}
